<template>
  <v-app>
    <v-container fluid class="main-container">
      <v-row justify="center" align="center">
        <v-col cols="12" md="6" class="text-center">
          <img alt="Vue logo" src="./assets/logo.png" class="logo" />
          <div class="tagline">CURATED GIFTING</div>
          <v-row justify="center" class="mt-15">
            <v-col cols="4" sm="4" md="3" class="text-center">
              <v-btn
                href="mailto:contact@taufaa.in"
                target="_blank"
                rel="noopener"
                block
                text
                color="yellow darken-1"
              >
                contact@taufaa.in
              </v-btn>
            </v-col>
            <v-col cols="4" sm="4" md="3" class="text-center">
              <v-btn
                icon
                href="https://www.instagram.com/taufaa.india?igsh=MXRpeXNtcmw1aWlyeg=="
                target="_blank"
                rel="noopener"
              >
                <v-icon style="color: #f8d042" large>mdi-instagram</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="4" sm="4" md="3" class="text-center">
              <v-btn
                href="tel:+919916855555"
                target="_blank"
                rel="noopener"
                block
                text
                color="yellow darken-1"
              >
                +91 9916855555
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "HelloWorld",
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
.v-btn,
.v-tab {
  text-decoration: none !important;
  text-transform: none !important;
  letter-spacing: normal !important;
  font-size: 16px;
}

.tagline {
  font-size: 1.2rem;
  letter-spacing: 3px;
}

.main-container {
  font-family: "Crimson Text", serif;
  height: 100vh;
  background-color: #031e40;
  color: #f8d042;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.logo {
  width: 50%;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .tagline {
    font-size: 14px !important;
  }
}
</style>
